@import '_scss/settings';

.c-file-upload-wrapper {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 100%;
}

.c-file-upload {
  position: relative;
  margin: 0 $spacing-small $spacing-small 0;
  padding: 0;
  top: 0;
  width: 100%;
  z-index: 9;

  & + .c-file-upload__message {
    margin: $spacing-medium auto 0;
  }

  &:first-child {
    margin: 0 0 $spacing-large 0;
  }

  input[type='file'] {
    width: 100% !important;
  }
}

.c-file-upload--uploaded {
  margin-bottom: 10px;
  padding: 0;

  .c-file-upload__attached {
    border-color: transparent;
  }

  .c-file-upload__uploaded-info {
    padding: 0 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 86px);
    max-width: none;
  }

  & + .c-file-upload__message {
    margin: $spacing-medium auto 0;
  }
}

.c-file-upload--no-padding {
  transition: padding 0.2s ease-in-out;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
}

.c-file-upload__dropzone {
  transition: padding 0.2s ease-in-out;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
}

.c-file-upload__list-item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;

  background-color: $color-neutral-x-light;
  border: 1px solid $color-neutral;
  border-radius: $tiny-radius;
  cursor: default;

  input[type='file'] {
    width: 100% !important;
  }
}

.c-file-upload--import-sporters {
  min-height: 210px;
  max-height: 60vh;

  max-width: 80vw;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-neutral-x-light;
  border-radius: 4px;
  flex-direction: column;
  padding: 24px;

  svg {
    flex: 1 0 auto;
    margin-bottom: $spacing-small;
  }

  .c-input__label {
    cursor: pointer;
    font-family: $font-family-regular;
    margin: 0;
    text-transform: none;
    user-select: none;

    @include inuit-font-size(13px, 1);
  }
}

.c-file-upload__dropzone-button {
  height: 32px;
  width: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid $color-neutral;
  border-radius: $tiny-radius;
  background-color: $color-neutral-x-light;
  flex-wrap: wrap;
  cursor: pointer;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  max-height: 32px;
  padding: 0;

  svg {
    flex: 0 0 15px;
    margin-right: 10px;
    margin-bottom: 1px;
  }

  input[type='file'] {
    width: 100% !important;
  }

  &:focus-within {
    outline-offset: 0;
    outline: 2px solid $color-primary;
  }
}

.c-file-upload__message {
  max-width: 24rem;
  text-align: center;
  color: $color-neutral-dark;
  margin-bottom: $spacing-small + $spacing-medium;

  &.c-file-upload__message-success {
    color: $color-primary-dark;
    margin: ($spacing-medium + $spacing-small) auto 0;
  }

  &.c-file-upload__message-failed {
    color: $color-error;
  }

  .c-file-upload--state-uploaded & {
    margin: $spacing-medium 0;
  }
}

.c-file-upload__attached {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: $tiny-radius;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: $spacing-medium $spacing-small;
  color: $color-primary-dark;
  text-align: left;

  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    margin-bottom: 0;
  }

  span {
    display: block;
  }
}

.c-file-upload__uploaded-info {
  position: relative;
  width: 100%;
  max-width: 100px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: $spacing-small $spacing-small 0;
}

.c-file-upload__attached-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;

  align-self: flex-end;
  z-index: 8;

  position: absolute;
  padding: 0;
  top: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  max-width: 80px;
  right: 10px;

  .c-file-upload--state-uploaded & {
    position: relative;
    align-self: center;
    max-width: none;
    height: auto;
    right: 0;
    justify-content: center;

    * + * {
      margin-left: $spacing-small;
    }
  }

  &.c-file-upload__attached-actions-relative {
    position: relative;
    right: $spacing-small;
    max-width: none;
    justify-content: flex-end;

    position: absolute;
    height: 40px;
    top: calc(100% - 44px);
    z-index: 9;

    * + * {
      margin-left: $spacing-small;
    }
  }
}

.c-file-upload__filename {
  width: 100%;
  // text-align: center;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include inuit-font-size(12px, 1);
}

// States:
.c-file-upload--state {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: $tiny-radius;
  border: 1px dashed $color-neutral;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  flex-direction: column;

  min-height: 210px;
  max-height: 60vh;
  max-width: 80vw;

  margin: 0;
  background: $color-neutral-x-light;
  padding: $spacing-small + $spacing-medium;
  // in Modal?
  // width: 352px;

  > svg {
    margin-bottom: $spacing-small;
  }

  .c-input__label {
    cursor: pointer;
    font-family: $font-family-regular;
    margin: 0;
    text-transform: none;
    user-select: none;

    @include inuit-font-size(13px, 1);
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    background-color: $color-primary-10;
    border-color: $color-primary-25;
  }

  .c-file-upload--disabled &,
  .c-file-upload--disabled &:hover,
  .c-file-upload--disabled &:active,
  .c-file-upload--disabled &:focus {
    background-color: $color-neutral-light;
    border: 1px solid $color-neutral;
  }

  .c-media__uppy-status-bar .uppy-Root {
    // max-width: 90%;
    margin: auto;
  }
}

.c-file-upload--state-small {
  position: relative;
  min-height: 130px;
  padding: $spacing-small;

  padding: 0;
  align-items: stretch;

  .c-media {
    .uppy-is-drag-over::after {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .c-media__upload {
    margin: 0;
  }

  .c-media__upload-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 8px;
    z-index: 1;
  }

  .c-file-upload__message {
    max-width: 14rem;
    margin: 8px 0 12px;
  }

  .c-input__label {
    display: flex;
  }

  .c-media__uppy-status-bar {
    max-width: calc(100% + 16px);
    width: 100%;
    margin-left: 0;
  }
}

.c-file-upload--state-uploaded {
  border: 1px solid $color-primary;

  &.c-file-upload--import-sporters {
    min-width: 352px;
  }
}

.c-file-upload--rejected {
  border: 1px dashed $color-error;
  background-color: $color-error-25;
}

.c-file-upload--dragging {
  background-color: $color-primary-25;
}

.c-file-upload--uploading {
  svg {
    animation: spin 2s infinite linear;
    opacity: 0.5;
  }
}

// Uploader Modal
.c-file-upload__link {
  color: $color-primary;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  text-transform: none;
  margin: auto;
}

.c-file-upload--wrapper-states {
  min-height: 210px;
  max-height: 60vh;
  min-width: 352px;
  width: 352px;
  max-width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 0;
  margin: auto;

  .c-progress-bar--secondary .c-progress-bar__bar {
    height: 8px;
  }

  .c-progress-bar--secondary:after {
    height: 8px;
  }

  .c-file-upload__message {
    margin: 0;
  }

  > svg {
    flex: 1 0 96px;
    margin-bottom: $spacing-medium;
  }
}

.c-file-upload--wrapper-states-errors {
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
  max-height: 200px;
  margin-bottom: 16px;
  padding: 0;
}

.c-file-upload-wrapper--one-image {
  display: flex;
  flex-direction: column;
  height: 100%;

  .c-file-upload {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
  }

  .c-file-upload__dropzone {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin-bottom: $spacing-medium;
  }
  .c-file-upload--state {
    min-height: 100px;

    svg {
      margin-bottom: 0;
    }
  }

  .c-file-upload__attached-actions {
    right: 8px;
    top: 28px;
    height: auto;
    width: auto;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.c-file-upload__logo-wrapper {
  position: relative;
  overflow: hidden;
  height: 100px;
}

.c-file-upload__logo {
  position: absolute;

  max-width: 85%;
  max-height: 85%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}

.c-file-upload--disabled {
  cursor: inherit;
}

.c-file-upload__image-wrapper {
  height: auto;
  overflow: hidden;
}

.c-file-upload__image {
  width: 100%;
  object-fit: cover;
  max-width: none;
  height: 210px;
}

.c-file-upload__image-wrapper-disabled {
  height: 210px;
}

.c-file-upload__video {
  margin-bottom: $spacing-medium;
}

.c-file-upload__video,
.c-file-upload__dropzone {
  .video-react {
    .video-react-poster {
      background-size: cover;
    }

    .video-react-big-play-button {
      transition: all 0.2s !important;
      line-height: 1.5em;
      background: $color-primary-dark-50;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      border: 0;

      &.video-react-big-play-button-center {
        // top: calc(50% - 1.5em);
        // left: calc(50% - 1.5em);
        margin-top: 0;
        margin-left: 0;
        transform: translate(-50%, -50%);
      }

      @include mq($from: large) {
        width: 3em;
        height: 3em;
        line-height: 3em;
      }
    }

    .video-react-icon-play-arrow::before,
    .video-react-big-play-button::before,
    .video-react-play-control::before,
    .video-react-bezel .video-react-bezel-icon-play::before {
      font-size: 1.25em;

      @include mq($from: large) {
        font-size: 2.5em;
      }
    }

    .video-react-control-bar {
      .video-react-icon-play-arrow::before,
      .video-react-big-play-button::before,
      .video-react-play-control::before,
      .video-react-bezel .video-react-bezel-icon-play::before {
        font-size: 1.75em;
      }
    }

    &:hover .video-react-big-play-button,
    .video-react-big-play-button:focus {
      background-color: $color-primary-dark;
    }
  }
}

.c-file-upload--thumb-and-video-wrapper {
  .c-file-upload:first-child {
    margin-bottom: $spacing-medium;
  }

  .c-file-upload-wrapper--one-image {
    display: inline-flex;

    .c-file-upload__dropzone {
      margin-bottom: 0;
    }
  }

  .c-file-upload--state {
    max-width: none;
    min-height: 210px;
  }
}

// media: TODO @Fabian Move to own SCSS File
.c-media {
  position: relative;
}

.c-media--main {
  margin: 0 -16px;
  width: auto;

  background: $color-neutral-x-light;
  padding-bottom: 8px;

  .c-card__body--modal & {
    background-color: $transparent;
    padding-bottom: 0;
  }
}

.c-media-wrapper {
  padding: 0;
  margin-bottom: 8px;
  min-height: 100px;

  .video-react {
    .video-react-poster {
      background-size: cover;
    }

    .video-react-big-play-button {
      transition: all 0.2s !important;
      line-height: 1.5em;
      background: rgba($color-primary, 0.7);
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      border: 0;

      &.video-react-big-play-button-center {
        margin-top: 0;
        margin-left: 0;
        transform: translate(-50%, -50%);
      }

      @include mq($from: large) {
        width: 3em;
        height: 3em;
        line-height: 3em;
      }
    }

    .video-react-icon-play-arrow::before,
    .video-react-big-play-button::before,
    .video-react-play-control::before,
    .video-react-bezel .video-react-bezel-icon-play::before {
      font-size: 1.25em;

      @include mq($from: large) {
        font-size: 2.5em;
      }
    }

    .video-react-control-bar {
      .video-react-icon-play-arrow::before,
      .video-react-big-play-button::before,
      .video-react-play-control::before,
      .video-react-bezel .video-react-bezel-icon-play::before {
        font-size: 1.75em;
      }
    }

    &:hover .video-react-big-play-button,
    .video-react-big-play-button:focus {
      background: rgba($color-primary, 1);
    }
  }

  &.o-flex {
    .c-icon.c-icon--medium {
      width: 24px;
      height: 24px;
      animation: spin 2s infinite linear;
    }
  }

  .c-card__body--modal & {
    margin-bottom: 0;
  }
}

.c-media__gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  border: 1px dashed transparent;
  padding: 8px 0;

  &.c-media__gallery--editable {
    justify-content: flex-start;
  }

  .c-button {
    padding: 0;
    display: block;
    overflow: hidden;
    margin: 4px;
  }

  .c-tooltip-popper__message-video {
    max-width: 200px;
    text-align: left;
  }

  .c-media__gallery-thumb.c-file-upload--uploading {
    width: 70px;
  }

  .c-card__body--modal & {
    padding: 0;
  }
}

.c-media__gallery-thumb-wrapper {
  position: relative;

  &.is-draggable > .c-button.c-media__gallery-thumb {
    cursor: grab;
  }

  &.is-dragging {
    z-index: 10;

    > .c-button.c-media__gallery-thumb {
      cursor: grabbing;
    }
  }

  .c-button.c-button--circular:not(.c-tooltip__trigger) {
    z-index: 2;
    opacity: 0;
    position: absolute;
    bottom: 4px;
    right: 4px;
    box-shadow: 0 0 5px -2px black;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    &:not(.is-dragging) .c-button.c-button--circular:not(.c-tooltip__trigger) {
      opacity: 1 !important;
    }
  }
}

.c-media__gallery-thumb {
  width: 70px;
  height: 70px;
  display: flex;
  margin: 4px;
  position: relative;
  background-color: $color-neutral-light;

  &.c-button {
    border: 0;
    border-radius: 0;

    .c-media__gallery-thumb.c-media__gallery-thumb-empty.c-file-upload--uploading {
      margin: 0;
      height: 100%;
    }
  }

  > .c-icon {
    position: absolute;
    border-radius: $circular-radius;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    background: rgba($color-primary, 0.7);
    z-index: 0;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    text-transform: none;

    @include inuit-font-size(8px, 1);
  }

  &.c-file-upload--uploading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: auto;
    box-shadow: inset 0 0 6px -5px $color-neutral-x-dark;
  }
}

.c-media__gallery-thumb.c-media__gallery-thumb-empty.c-file-upload--uploading::after {
  content: '';
  padding-top: 56%;
}

.c-media__gallery-thumb-selected {
  img {
    opacity: 0.25;
    filter: saturate(0);
  }

  &::after {
    content: '';
    height: 4px;
    background: $color-primary;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .c-media__gallery-thumb.c-media__gallery-thumb-empty.c-file-upload--uploading {
    margin: 0;
  }
}

.c-media__gallery-thumb-empty {
  &.c-file-upload--uploading svg {
    animation: none;
    background: transparent;
    width: 16px;
    height: 16px;
    border-radius: 0;
  }
}

.c-media__upload {
  display: flex;
  position: relative;
  margin: 0 0 #{$spacing-small * 1.5};
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  .c-media__gallery {
    width: 100%;
    padding: $spacing-small $spacing-small 0;
  }

  .c-media__upload-area {
    padding: #{$spacing-small * 1.5} $spacing-medium;
  }

  .c-media__gallery-thumb {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &.c-file-upload--uploading {
      width: 100px;
    }
  }

  &.uppy-is-drag-over {
    background: $color-primary-light;
    border: 1px dashed $color-primary;

    &::after {
      content: none;
    }
  }
}

.c-media__upload-area {
  width: 100%;
  padding: 0;
  position: relative;
  z-index: 0;

  .c-input__label {
    display: flex;
    margin-bottom: 0;
  }

  .uppy-Root .uppy-FileInput-container {
    margin-bottom: 0;

    .uppy-FileInput-btn {
      background-color: white;
      border: 1px solid #d6dadc;
      color: #7a8198;
      padding: 8px 16px;
      text-transform: none;
      font-family: 'Lato', sans-serif;
      font-weight: normal;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
      line-height: 1.1;
      margin-bottom: 0;
    }
  }

  .c-media__uppy-status-bar {
    .uppy-StatusBar {
      background-color: $color-neutral-light;
    }

    .uppy-StatusBar:not([aria-hidden='true']) {
      margin-top: $spacing-small * 1.5;
    }

    .uppy-StatusBar-statusPrimary button.uppy-StatusBar-details {
      font-family: $font-family-bold;
    }
  }
}

.c-media__empty-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color-primary-dark-25;
}

.c-file-upload-wrapper--one-image {
  padding: $spacing-small;
  min-height: 0;
  height: auto;
  flex: 1;
  position: relative;
  background-color: $color-neutral-x-light;

  max-height: 100px;
  overflow: hidden;

  &.c-file-upload-wrapper--readonly,
  &.c-file-upload-wrapper--readonly:hover {
    background-color: $color-neutral-light;
    border: 1px solid $color-neutral;
  }

  &:not(.c-file-upload-wrapper--readonly):hover,
  &:not(.c-file-upload-wrapper--readonly):active,
  &:not(.c-file-upload-wrapper--readonly):focus {
    background-color: $color-neutral-x-light;
    border: 1px dashed $color-primary-dark-50;

    .c-input__label.c-file-upload__dropzone-button {
      opacity: 1 !important;
    }
  }

  .c-media {
    position: static;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;

    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-neutral;
    }
  }

  .c-media__upload {
    margin: 0;
    position: static;
    width: 100%;
    height: 100%;

    > img {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      text-align: center;
      color: $color-neutral;

      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    .c-media__upload-area .c-icon.c-icon--huge {
      margin-bottom: 12px;
      display: none;
    }

    .c-file-upload__message {
      display: none;
    }

    .c-input__label.c-file-upload__dropzone-button {
      border: 0;
      outline: 1px solid $color-neutral;
      display: flex;
      z-index: 1;
      opacity: 0;
      transition: all 0.2s ease-in-out;

      &:hover,
      &:focus,
      &:focus-within {
        opacity: 1;
        background-color: $color-neutral-x-light;
      }
    }
  }

  .c-media__upload.uppy-is-drag-over {
    &::after {
      bottom: -4px;
    }

    > img {
      z-index: 1;
    }
  }

  .c-media__uppy-status-bar,
  .c-media__uppy-progress-bar {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    .uppy-ProgressBar-inner {
      background-color: $color-success;
      box-shadow: 0;
    }
  }

  .c-media__uppy-status-bar .uppy-StatusBar {
    height: 100%;
    background-color: rgba($color-neutral-x-light, 0.9);

    .uppy-StatusBar-content {
      padding-left: 0;
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;

      @include inuit-font-size(10px);
    }

    .uppy-StatusBar-actions {
      justify-content: center;
      background-color: $transparent;
      top: auto;
      left: 0;
      right: 0;
      bottom: 4px;
      width: 100%;
    }

    .uppy-StatusBar-status {
      padding-right: 0;
    }

    .uppy-StatusBar-statusSecondary,
    .uppy-u-reset.uppy-StatusBar-details {
      display: none;
    }

    &:not([aria-hidden='true']) {
      margin-top: 0;
    }
  }

  .c-media__upload-area {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;

    .uppy-Root {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      max-width: 100%;
    }

    .uppy-StatusBar[aria-hidden='true'] {
      user-select: none;
      height: 0;
    }
  }
}

.uppy-FileInput-input {
  @include inuit-hidden-visually();
}
