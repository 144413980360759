@import '../../_scss/settings';

.c-icon {
  width: $small-icon;
  height: $small-icon;
  background-color: $transparent;
  pointer-events: none;

  use {
    fill: $color-neutral-dark;
  }
}

.c-icon--input {
  position: absolute;
  width: $small-icon;
  height: $small-icon;
  // padding: $spacing-small;
  right: $spacing-small + $spacing-tiny;
  top: $spacing-small;
  // transform: translateY(-50%);
  pointer-events: none;
}

.c-icon--small {
  width: $small-icon;
  height: $small-icon;
}

.c-icon--small-medium {
  width: $small-medium-icon;
  height: $small-medium-icon;
}

.c-icon--medium {
  width: $medium-icon;
  height: $medium-icon;
}

.c-icon--huge {
  width: $huge-icon;
  height: $huge-icon;
}

.c-icon--x-huge {
  width: $x-huge-icon;
  height: $x-huge-icon;
}

@each $current-color-name, $current-color in $colors-list {
  .c-icon--fill-#{$current-color-name} {
    use {
      fill: $current-color !important;
    }
  }

  .c-icon--bg-#{$current-color-name} {
    background-color: $current-color;
  }

  .c-icon--stroke-#{$current-color-name} {
    stroke: $current-color;

    use {
      stroke: inherit !important;
    }
  }
}

.c-icon--rotated-90 {
  transform: rotate(-90deg);
}

.c-icon--mirrored {
  transform: rotate(180deg);
}
