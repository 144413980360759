@import '../../_scss/settings';

.c-badge {
  display: inline-block;
  background-color: $color-primary-10;

  border-radius: $small-medium-radius;
  padding: 0 $spacing-small;
  margin-right: $spacing-tiny;

  text-align: center;
  font-family: $font-family-bold;
  color: $color-primary;

  white-space: nowrap;
  overflow: hidden;

  @include inuit-font-size(10px, 2);
}

.c-badge--rank {
  color: $color-neutral-dark;
  background-color: $color-neutral-light;
  padding: 0;
  min-width: 24px;
  height: 24px;
  margin-right: $spacing-tiny;

  @include inuit-font-size(10px, 2.5);
}

.c-badge--bullet {
  width: 6px;
  height: 6px;

  background-color: #7a8198;
  margin-right: 8px;
}

.c-badge--notification {
  border-radius: $medium-radius;
  border: 1px solid transparent;
  padding: $spacing-tiny $spacing-medium;

  display: inline-flex;
  align-items: center;
  text-transform: none;
  font-weight: normal;

  font-family: $font-family-regular;
  font-weight: normal;
  color: $color-neutral-x-light;
  background-color: $color-success;
  opacity: 0;
  z-index: 40;

  position: absolute;
  top: -$spacing-large;
  left: 50%;
  transform: translateX(-50%);

  transition: top 0.3s ease-in-out, opacity 0.1s ease-in-out;
  @include inuit-font-size($inuit-global-font-size, 1.7);
}

.c-badge--error {
  background-color: $color-error;
}

.c-badge--show {
  top: $spacing-large;
  opacity: 1;
}

.c-badge--tag {
  padding: $spacing-mini ($spacing-small * 1.5) $spacing-mini
    ($spacing-small * 1.5);
  font-family: $font-family-regular;
  color: $color-neutral-x-dark;

  @include inuit-font-size($inuit-global-font-size, 1.5);
}

.c-badge--in-button {
  .c-button & {
    margin-left: $spacing-small;
    margin-right: -$spacing-small;
  }

  .c-button--primary & {
    background-color: white;
  }
}

.c-badge--large {
  min-width: $spacing-medium * 1.5;
  border-radius: $medium-radius;
  @include inuit-font-size(16px, 1.5);
}
