@import '../../_scss/settings';

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.5s ease 0.1s;
  overflow: auto;

  .c-card--panel {
    transform: translateX(100%);
    transition: transform 0.1s ease 0.5s;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;

  .c-card--panel {
    transform: translateX(0);
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;

  .c-card--panel {
    transform: translateX(100%);
  }
}

.c-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq($from: small) {
    left: 72px;
  }
}

.c-modal__content {
  position: absolute;
  border: 1px solid #ccc;
  background: #fff;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

// Code Right:
.c-modal__overlay {
  z-index: 101;
  background-color: rgba($color-neutral-x-dark, 0.5);
  overflow-x: hidden;

  @include mq($from: medium) {
    z-index: 101;
  }
}

.c-modal__content {
  top: $spacing-huge + $spacing-huge + $spacing-large - $spacing-tiny;
  bottom: $spacing-tiny + $spacing-medium + $spacing-huge;

  .c-card__header {
    border-top-left-radius: $spacing-small;
    border-top-right-radius: $spacing-small;
  }

  .c-card__footer {
    border-bottom-left-radius: $spacing-small;
    border-bottom-right-radius: $spacing-small;
  }

  .c-button__modal {
    position: absolute;
    right: $spacing-medium;
    top: $spacing-medium;
  }

  &.c-card--panel {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    bottom: 0;
    top: 0;
    width: 90%;

    .c-card__footer > p {
      max-width: 256px;
      margin: auto;
      text-align: center;

      @include inuit-font-size(12px);
    }

    @include mq($from: medium) {
      width: 70%;
    }

    @include mq($from: large) {
      width: calc(50% - 36px);
    }
  }

  @media (max-height: 800px) {
    top: $spacing-huge + $spacing-large;
    bottom: $spacing-large;
  }
}

.c-card-modal__overflow-visible .c-card__body {
  overflow-y: visible;
}

.c-card-modal__overflow-scroll .c-card__body {
  overflow-y: scroll;

  .o-layout {
    padding: 0 !important;
  }
}

.c-modal__content-auto-width {
  width: auto;
}

.c-modal__content-bottom-huge {
  bottom: $spacing-huge;
  margin-bottom: 0;
}

.c-modal__content-bottom-auto {
  bottom: auto;
}

.c-card--filter {
  max-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .c-card__header {
    height: auto;
  }

  .c-card__header--primary {
    padding: 24px 24px;
  }

  .c-button--absolute-top-right {
    right: 24px;
    top: 24px;

    .c-icon {
      width: 24px;
      height: 24px;
    }
  }

  .c-card__body--modal {
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $spacing-medium ($spacing-medium * 1.5) ($spacing-medium * 1.5);
    // max-height: calc(100vh - 176px);
    max-height: calc(100vh - 100px);
    overflow-y: scroll;

    > .c-card__body-wrapper {
      flex: 0 0 auto;
      margin-bottom: 16px;
      background: $color-neutral-light;
      padding-top: 16px;
    }
  }

  .c-input__group-select {
    .c-input__label {
      color: $color-primary-dark;
    }
    .c-input__group-select {
      margin-bottom: 24px;
    }
  }

  .c-card__footer--secondary {
    // position: fixed;
    // bottom: 16px;
    // z-index: 50;
    // right: 24px;

    margin-top: auto;
  }
}
