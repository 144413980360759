.c-iframe--content {
  // background: #ffffff;
  border: 0;
  // height: 100%;
  // width: 100%;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // height: 100%;

  //
  width: 1px !important;
  min-width: 100%;
  min-height: 100vh;
  // -webkit-overflow-scrolling: touch !important;
  // overflow: scroll !important;

  display: none;
}

.c-iframe--show {
  display: block;
}
