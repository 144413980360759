@import '../../_scss/settings';

.c-organisation {
  width: auto;
  min-width: 100%;

  border: 1px solid transparent;
  padding: $spacing-tiny 0;

  display: flex;
  align-items: center;
  line-height: 1.7;
  text-decoration: none;

  font-family: $font-family-bold;
  color: $color-neutral-dark;
  background: $transparent;

  margin-top: $spacing-small;

  img {
    max-width: 24px;
    max-height: 24px;
    margin-right: 12px;
  }

  .c-icon {
    margin-left: $spacing-tiny;
    margin-right: 0;
  }

  .c-card-modal__body-head-breadcrumb & {
    margin-top: 0;
    min-width: 0;
    width: 100%;
  }
}
