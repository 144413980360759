@import '../../_scss/settings';

.c-graphic {
  width: 100%;
  max-width: 400px;
  min-width: 265px;
  height: 20px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: $spacing-medium;
  margin-bottom: $spacing-large * 1.25;

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: -$spacing-medium;
    height: 1px;
    background-color: $color-neutral;
  }

  [style='flex-direction: column;'] & {
    min-width: auto;
  }
}

.c-graphic--maturity {
}

.c-graphic__area {
  position: relative;
  height: 100%;
  width: calc(100% / 3);
  //background-color:  #f47d7d;
  background-color: rgba($color-progress-red, 0.65);
  //border-top: 1px solid rgba($color-progress-red, .65);
  //border-bottom: 1px solid rgba($color-progress-red, .65);
}

.c-graphic__lt-area,
.c-graphic__gt-area {
  //background-color: #F5F5F5;
  //border-color: rgba($color-progress-green, .65);
  background-color: rgba($color-progress-green, 0.65);
}

.c-graphic__lt-area {
  //border-left: 1px solid rgba($color-progress-green, .65);
}

.c-graphic__gt-area {
  //border-right: 1px solid rgba($color-progress-green, .65);
}

.c-graphic__area-label {
  position: absolute;
  bottom: -$spacing-large * 1.25;

  @include inuit-font-size(10px);

  &::before {
    content: '';
    background-color: $color-neutral;

    position: absolute;
    top: -100%;
    left: 50%;
    transform: translateX(-50%);

    height: $spacing-medium;
    width: 1px;
  }
}

.c-graphic__lt-area-label {
  //right: -$spacing-medium;
  right: -9px;
}

.c-graphic__middle-area-label {
  left: 50%;
  transform: translateX(-50%);
}

.c-graphic__gt-area-label {
  //left: -$spacing-medium;
  left: -12px;
}

.c-graphic__position {
  position: absolute;
  width: 5px;
  height: $spacing-medium * 1.5;
  background-color: $color-primary-dark;
  //left: calc(calc(100% - 5px) * 0.2);
  top: -2px;
  transform: translateX(-50%);
}

.c-graphic--pah {
  .c-graphic__position {
    //left: calc(calc(calc(100% - 4px) / 20) * 8);
  }

  .c-graphic__lt-area {
    width: calc(calc(6 / 20) * 100%);
  }
  .c-graphic__middle-lt-area {
    width: calc(calc(3 / 20) * 100%);
    //background-color:  rgba($color-progress-yellow, .75);
    //border-color: $color-neutral;
    background-color: #fdecaabf;
  }
  .c-graphic__middle-gt-area {
    width: calc(calc(6 / 20) * 100%);
  }
  .c-graphic__gt-area {
    width: calc(calc(5 / 20) * 100%);
  }

  .c-graphic__middle-lt-area-label {
    right: -11px;
  }
  .c-graphic__middle-gt-area-label {
    right: -10px;
  }
  .c-graphic__gt-area-label {
    left: auto;
    right: -7px;

    &::before {
      left: auto;
      right: 6px;
    }
  }
}

.c-table--report-tab
  .c-table__row:not(.c-table__row--unavailable):hover
  .c-table-cell.c-table-cell--even-half,
.c-table--report-tab
  .c-table__row:not(.c-table__row--unavailable):focus-within
  .c-table-cell.c-table-cell--even-half {
  .c-graphic {
    &::after {
      background-color: $color-neutral-dark;
    }
  }

  .c-graphic__lt-area,
  .c-graphic__gt-area {
    border-color: $color-neutral-dark;
  }
  .c-graphic__lt-area {
    //border-left: 1px solid $color-neutral-dark;
  }
  .c-graphic__gt-area {
    //border-right: 1px solid $color-neutral-dark;
  }

  .c-graphic__area-label {
    &::before {
      background-color: $color-neutral-dark;
    }
  }
  .c-graphic--pah {
    .c-graphic__middle-lt-area {
      border-color: $color-neutral-dark;
    }
  }
}

// .c-table--report-tab .c-table__row--unavailable:hover .c-table-cell.c-table-cell--even-half .c-graphic__area-label::before, .c-table--report-tab .c-table__row--unavailable:focus-within .c-table-cell.c-table-cell--even-half .c-graphic__area-label::before {
//   background-color: lightgray;
//   }
//   .c-table--report-tab .c-table__row--unavailable:hover .c-table-cell.c-table-cell--even-half .c-graphic::after, .c-table--report-tab .c-table__row--unavailable:focus-within .c-table-cell.c-table-cell--even-half .c-graphic::after {
//   background-color: lightgray;
//   }
