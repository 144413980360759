///* ========================================================================
//   #COLORS
//   ======================================================================== */

// Tint & shade helper functions (http://bourbon.io/docs/#tint-shade)

// Makes a color lighter
@function tint($color, $percent) {
  @return mix(#fff, $color, $percent);
}

// Makes a color darker
@function shade($color, $percent) {
  @return mix(#000, $color, $percent);
}

// Base colors inspired by clrs.cc by @mrmrs_

// $aqua                       : #7FDBFF;
// $blue                       : #0074D9;
// $navy                       : #001F3F;
// $teal                       : #39CCCC;
// $green                      : #2ECC40;
// $olive                      : #3D9970;
// $lime                       : #01FF70;

// $yellow                     : #FFDC00;
// $orange                     : #FF851B;
// $red                        : #FF4136;
// $fuchsia                    : #F012BE;
// $purple                     : #B10DC9;
// $maroon                     : #85144B;

// $white                      : #FFFFFF;
// $silver                     : #DDDDDD;
// $grey                       : #AAAAAA;
// $black                      : #111111;

$transparent: rgba(0, 0, 0, 0);
// $lightblue: rgb(65, 135, 237);

// Neutral colors:
// $white: #fff;
// $white10: rgba(255, 255, 255, 0.1);
// $white25: rgba(255, 255, 255, 0.25);
// $white30: rgba(255, 255, 255, 0.3);
// $white50: rgba(255, 255, 255, 0.5);
// $black: #000;

// $grey: #e0e2e5;
// $lightgrey: #fafafa;
// $middlegrey: #ccc;
// $darkgrey: #969696;

$dodger-blue: #3c84f0;
$night-blue: #071649;
$package-blue: #008dfa;
// $dodger-blue-light: rgb(235, 242, 254); // == color-primary-10
$dodger-blue-light: rgb(225, 233, 247); //!important

$green-blue: #00c28f;
$red: #ff3333;
$orange: #ff9800;

$white: #fff;
$pale-grey: #f4f5f7;
$silver: #d6dadc;
$steel: #7a8198;
$charcoalgrey: #34373e;
$black: #000;

// drawer bg: $color-secondary
// icon-grey: #7A8198 + text color ==
// navigation on drawer hover: 244, 245, 247, .08 + color white
// navigation on drawer active: $color-primary + color white
// nav-mobile -logout: 255, 255, 255 , .08 + color #7A8198
// $color-neutral-pale-grey: $pale-grey;
// $color-neutral-silver: $silver;
// $color-neutral-steel: $steel;

// Brand colors
$color-neutral-x-light: $white;
$color-neutral-light: $pale-grey;
$color-neutral: $silver;
$color-neutral-dark: $steel;
$color-neutral-x-dark: $charcoalgrey;
$color-neutral-xx-dark: $black;

$color-primary: $dodger-blue;
$color-primary-10: rgba($dodger-blue, 0.1); // rgb(225, 233, 247)
$color-primary-25: rgba($dodger-blue, 0.25);
$color-primary-light: $dodger-blue-light;

$color-primary-dark: $night-blue;
$color-primary-dark-10: rgba($night-blue, 0.1);
$color-primary-dark-15: rgba($night-blue, 0.15);
$color-primary-dark-25: rgba($night-blue, 0.25);
$color-primary-dark-50: rgba($night-blue, 0.5);

$color-secondary: $orange;
$color-tertiary: $package-blue;
// UI colors
$color-success: $green-blue;
$color-success-25: rgba($green-blue, 0.25);
$color-error: $red;
$color-error-25: rgba($red, 0.25);
$color-notice: $orange;
$color-notice-25: rgba($orange, 0.25);

$color-progress-red: #ed2720;
$color-progress-orange: #ff8f00;
$color-progress-orange-light: #ffc107;
$color-progress-yellow: #feea3d;
$color-progress-lime: #b7e500;
$color-progress-green: #35c600;
$color-progress-average: rgba($night-blue, 0.1);

$colors-list: (
  'transparent': rgba(0, 0, 0, 0),
  'color-neutral-x-light': #fff,
  'color-neutral-light': #f4f5f7,
  'color-neutral': #d6dadc,
  'color-neutral-dark': #7a8198,
  'color-neutral-x-dark': #34373e,
  'color-neutral-xx-dark': #000,
  'color-primary': #3c84f0,
  'color-primary-10': rgba(#3c84f0, 0.1),
  'color-primary-25': rgba(#3c84f0, 0.25),
  'color-primary-dark': #071649,
  'color-primary-dark-10': rgba(#071649, 0.1),
  'color-primary-dark-15': rgba(#071649, 0.15),
  'color-primary-dark-50': rgba(#071649, 0.5),
  'color-success': #00c28f,
  'color-success-25': rgba(#00c28f, 0.25),
  'color-error': #f33,
  'color-error-25': rgba(#f33, 0.25),
  'color-notice': #ff9800,
  'color-notice-25': rgba(#ff9800, 0.25)
);

// Syntax colors (default is solarized)
$color-syntax-background: #111111; // body text / default code / primary
$color-syntax-primary: #93a1a1; // body text / default code / primary
$color-syntax-secondary: #586e75; // comments / secondary content
$color-syntax-tertiary: #cb4b16; // constants
$color-syntax-special: #dc322f; // regex, special keywords
$color-syntax-keywords: #268bd2; // reserved keywords
$color-syntax-strings: #2aa198; // strings, numbers
$color-syntax-operators: #859900; // operators, other keywords
