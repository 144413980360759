@import '../../_scss/settings';

.c-breadcrumbs {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 0 $spacing-small;
  flex: 1 0 100%;
  flex-wrap: wrap;
}

.c-breadcrumbs-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .c-breadcrumbs {
    flex: auto;
  }
}

.c-breadcrumbs-wrapper--for-lists {
  //   margin-top: $spacing-small;
  //   padding-bottom: $spacing-small;
  align-items: center;
  //   border-bottom: 1px solid $color-neutral;

  .c-card__body--tools + & {
    margin-top: $spacing-medium;
  }

  .c-breadcrumbs {
    margin: 0 $spacing-small;
  }

  & + .c-list {
    margin-top: 0;
  }

  > .c-icon {
    flex: 0 0 $small-icon;
  }
  > .c-organisation {
    margin-top: 0;
    min-width: 0;
    width: 100%;
  }

  > *:not(:first-child) {
    margin-left: $spacing-small;
  }

  .c-breadcrumbs__icon {
    display: none;
  }
}

.c-breadcrumbs--benchmarks {
  .c-breadcrumbs__item {
    border: 1px solid transparent;
    border-top-color: $color-primary-dark-10;
    border-bottom-color: $color-primary-dark-10;

    // margin-left: -4px;
    margin-right: 0;
    padding: $spacing-small $spacing-small $spacing-small $spacing-tiny;

    &:nth-child(2) {
      padding: $spacing-small $spacing-small $spacing-small $spacing-medium;
    }

    &:first-child {
      background-color: $color-primary;
      margin-right: $spacing-medium;
      border-left-color: $color-primary;
      border-top-color: $color-primary;
      border-bottom-color: $color-primary;
      border-right: 1px solid transparent;
      padding: 0;
      margin-right: 0;

      .c-breadcrumbs__link {
        color: $color-neutral-x-light;
        padding: $spacing-small $spacing-small $spacing-small 14px;
      }

      &:after {
        content: '';
        width: $spacing-small;
        position: absolute;
        background: transparent;
        border-top: $spacing-medium solid transparent;
        border-left: 10px solid $color-primary;
        border-bottom: $spacing-medium solid transparent;
        height: 100%;
        right: -11px;
      }
    }

    &:last-child {
      border-right-color: $color-primary-dark-10;
    }

    &:not(:first-child):not(:last-child) {
      &:after {
        right: -3px;
        top: calc(50% - 1px);
        transform: translateY(-50%);
      }
    }
  }
}

.c-breadcrumbs__item {
  position: relative;
  margin-right: $spacing-tiny;
  color: $color-primary;
  display: flex;
  align-items: center;

  &:not(:first-child):not(:last-child) {
    padding-right: $spacing-small;

    &:after {
      content: '';
      width: $spacing-medium + $spacing-mini;
      height: $spacing-medium + $spacing-mini;
      background-image: url('/assets/images/svgstore/breadcrumb.svg');

      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      right: -$spacing-tiny;
    }
  }
}

.c-breadcrumbs__icon {
  width: $spacing-medium;
  height: $spacing-medium;
  margin-right: $spacing-tiny;
  padding: 0;

  svg {
    width: $spacing-medium;
    height: $spacing-medium;
  }
}

.c-breadcrumbs__link {
  font-family: $font-family-bold;
  color: $color-primary;
  padding: 0 $spacing-small 0 $spacing-tiny;
  text-decoration: none;
}
