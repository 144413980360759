@import '../../_scss/settings';

.c-sidebar {
  transition: $global-transition-fast;
  display: flex;

  height: 100%;
  min-height: 100%;
  width: 72px;

  flex: 1 0 72px;
  overflow: hidden;

  position: fixed;
  z-index: 250;
  left: -72px;

  @include mq($from: small) {
    left: 0;

    &:hover {
      width: 250px;
    }
  }
}

.c-sidebar--uncollapsed {
  left: 0;
  width: 250px;
}

.c-sidebar__wrapper {
  background: $color-neutral-x-dark;
  display: flex;
  flex-direction: column;

  width: 100%;
}

.c-sidebar__header {
  display: flex;
  align-items: center;
  padding: 0;
  height: 72px;
  flex: 0 1 72px;

  margin: ($spacing-medium - $spacing-tiny) 0;
  cursor: pointer;
}

.c-sidebar__logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 72px;
  min-width: 72px;

  text-align: left;

  .c-sidebar__logo-img {
    max-width: 46px;
  }
}

.c-sidebar__user-info {
  font-family: $font-family-regular;
  color: $color-neutral-x-light;
  margin-left: $spacing-small;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include inuit-font-size(16px, 2);
}
